import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../../auth/authFunctions";
import { useSelectedAccount } from "../../hooks/useSelectedAccount";
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Icon, IconButton, Spinner } from "@chakra-ui/react";
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'; // React Data Grid Component
import { ColDef, RowClassParams } from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import download from 'downloadjs';
import FileDropzone from "../Files/FileDropzone";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useSearch } from "wouter";
import "../../styles/grid-styles.css";
import { NotificationContext } from '../../components/NotificationPane'; 
import { NotificationContextType } from "../../types";
import { FaHouse, FaQuestion, FaRegFile, FaRegFolder } from "react-icons/fa6";
import moment from "moment";
import byteSize from "byte-size";

export type dirType = {
  fileId: string;
  name: string;
  kind: string;
  lastModified: Date;
  createdOn: Date;
  size: Number;
}

function FilesComponent() {
  const { selectedAccount } = useSelectedAccount();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [loading, setLoading]= useState(true)
  const [fileData, setFileData] = useState<dirType[]>([])
  const [currentDir, setCurrentDir]= useState<string | null>(null);
  const [fileToHighlight, setFileToHighlight]= useState<string | null>(null);
  const search = useSearch();
  const [location] = useLocation();
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  function checkPathType(path: string) {
    // Check if the last part of the path contains a period, indicating a file
    const pathParts = path.split(/[/\\]/); // Split by either forward slash or backslash
    const lastPart = pathParts[pathParts.length - 1];
    
    if (lastPart.includes('.')) {
        return "File";
    }

    // If neither condition is met, it could be either, return uncertain
    return "Directory";
  }


  useEffect(() => {
    if (!selectedAccount) {
      return;
    }

    const params = new URLSearchParams(search);
    let filepathParam = params.get("filepath");
  
    // If filepathParam exists but doesn't include the selectedAccount file_path, prepend it
    if (filepathParam && !filepathParam.includes(selectedAccount.file_path ?? "")) {
      filepathParam = `${selectedAccount.file_path}/${filepathParam}`;
    }
  
    const type = filepathParam ? checkPathType(filepathParam) : null;
  
    if (!filepathParam) {
      setCurrentDir(selectedAccount.file_path);
    } else if (type === "Directory") {
      setCurrentDir(filepathParam);
    } else if (type === "File") {
      const newCurrentDir = filepathParam.split("/").slice(0, -1).join("/");
      setCurrentDir(newCurrentDir);
      setFileToHighlight(filepathParam.split("/").pop() ?? null);
    }
  }, [selectedAccount, search]);

  useEffect(() => {
    if (currentDir) {
      setLoading(true);
      getFiles(currentDir)
      .catch(() => {
        pushNotification("Error loading files", "error");
        setCurrentDir(selectedAccount?.file_path || null);
      });
    }
  }, [currentDir])
  
  async function getFiles(dir: string) {
    if (instance && account) {
      const accessToken = await getAccessToken(instance, account);
      const body = { 
        dir, 
        account_id: selectedAccount?.account_id,
        account_name: selectedAccount?.Account_name
      }
      const response = await fetch(`${import.meta.env.VITE_API_URL}/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify(body),
      });
      const responseJson = await response.json();
      await setLoading(false)

      if (responseJson.statusCode === 404)
        throw new Error("No files found");

      return await setFileData(responseJson);
    }
  }

  // !!! file download currently doesnt work - currently recieves a "ReadableStream"
  async function downloadFile(dir: string, file: string){
    if (instance && account) {
      try {
        const accessToken = await getAccessToken(instance, account);
        const body = { dir, file }
        const response = await fetch(`${import.meta.env.VITE_API_URL}/files/download`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
        
        const responseJson = await response.json();
        const byteArray = new Uint8Array(await responseJson.data);
        download(new Blob([await byteArray]), file);
        
        pushNotification(`File ${file} downloaded successfully`, "success");
      } catch(err) {
        pushNotification("Error downloading file", "error");
      } finally {
        setLoading(false)
      }
    }
  }

  const gridRef = useRef<AgGridReact<dirType>>(null);

  const handleClick = useCallback(() => {
    if (!currentDir) return;

    setLoading(true);
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const kind = selectedRows[0].kind;
    const name = selectedRows[0].name;
    if (kind === "file"){
      console.log(`Downloading the file ${name}...`)
      return downloadFile(currentDir, name)
    }
    const newCurrentDir = currentDir + `/${name}`
    return setCurrentDir(newCurrentDir);
  }, [currentDir]);

  function resetDir() {
    setCurrentDir(selectedAccount?.file_path || null);
  }

  function backwardDir() {
    if (currentDir === selectedAccount?.file_path || currentDir === null) return
    const newCurrentDir = currentDir.split("/").slice(0, -1).join("/");
    setCurrentDir(newCurrentDir);
  }

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef<dirType>[]>([
    { field: "kind", headerName: "Type", sort: "asc", sortIndex: 0, width: 50, cellRenderer: TypeRenderer },
    { field: "name", headerName: "File Name", sort: "asc", sortIndex: 1, minWidth: 200, flex: 1 },
    { field: "lastModified", headerName: "Modified On", cellDataType: 'date', filter: 'agDateColumnFilter', valueFormatter: (params) => {return params.value && moment(params.value).format('DD/MM/yyyy')}},
    { field: "createdOn", headerName: "Created On", cellDataType: 'date', filter: 'agDateColumnFilter', valueFormatter: (params) => {return params.value && moment(params.value).format('DD/MM/yyyy')}},
    { field: "size", headerName: "Size", valueFormatter: (params) => {return params.value && byteSize(params.value)}},
  ]);

  function TypeRenderer(params: CustomCellRendererProps){
    if (!params.data) return
    if (params.data.kind === "directory") return <Icon as={FaRegFolder} boxSize={4} />
    if (params.data.kind === "file") return <Icon as={FaRegFile} boxSize={4} />
    return <Icon as={FaQuestion} boxSize={4} />
  }

  const copyFilePathToClipboard = useCallback((name: string) => {
    if (!currentDir) return;

    const filePath = `${currentDir}/${name}`;
    console.log(location)
    const url = `${window.location.href.split('?')[0]}?accountid=${selectedAccount?.account_id}&filepath=${filePath}`;
    
    navigator.clipboard.writeText(url).then(() => {
      pushNotification("File path copied to clipboard", "success");
    }).catch(() => {
      pushNotification("Failed to copy file path", "error");
    });
  }, [currentDir, pushNotification]);
  
  const gridOptions = {
    // all even rows assigned 'my-shaded-effect'
    getRowClass: (params : RowClassParams) => {
      if (params.data.name === fileToHighlight) {
          return 'highlight-file';
      }
    },
    // Adding the context menu item
    getContextMenuItems: (params: any) => {
      //console.log(params);
      const copyFilePathOption = {
        name: 'Copy File Path',
        action: () => copyFilePathToClipboard(params.node.data.name)
      };
      return [
        copyFilePathOption,
        'copy', 'paste', 'separator', 'export'
      ];
    }
  }

  function reloadFiles () {
    if (currentDir)
      getFiles(currentDir);
  }

  if (fileData === undefined || loading) return <Spinner />
  return (
        <>
            {currentDir && 
            <Flex w="100%" h="100%" flexDirection={"column"}>
                <Box>
                    <IconButton m={2} aria-label='Search database' icon={<ChevronLeftIcon />} colorScheme='blue' onClick={backwardDir} isDisabled={currentDir===selectedAccount?.file_path}>Back</IconButton >
                    <IconButton aria-label='Search database' icon={<FaHouse />} colorScheme='blue' onClick={resetDir} isDisabled={currentDir===selectedAccount?.file_path}>Return to top directory</IconButton >
                </Box>
                <Breadcrumb m={1} color={"blue.100"}>
                {currentDir
                    .split("/")
                    .slice(1)
                    .map((dir, index) => {
                    const fullPath = currentDir.split("/").slice(0, index + 2).join("/");
                    return (
                        <BreadcrumbItem key={uuidv4()}>
                        <BreadcrumbLink onClick={() => setCurrentDir(fullPath)}>
                            {index === 0 ? "Home" : dir}
                        </BreadcrumbLink>
                        </BreadcrumbItem>
                    );
                    })}
                </Breadcrumb>
                <Box flex={1}>
                    <Flex w="100%" h="100%" flexDirection={"column"}>
                        <Box flex={3}>
                            <div className="ag-theme-quartz" style={{ width: "100%", height: "100%" }} >
                                <AgGridReact gridOptions={gridOptions} ref={gridRef} rowData={fileData} columnDefs={colDefs} rowSelection="single" onSelectionChanged={handleClick} />
                            </div>
                        </Box>
                        <Box>
                            <FileDropzone dir={currentDir} onUpload={reloadFiles} />
                        </Box>
                    </Flex>
                </Box>
            </Flex>
            }
        </>
  )

}

export default FilesComponent