import { useMsal, useAccount } from "@azure/msal-react";
import { getAccessToken } from "../../auth/authFunctions";
import {useCallback, useContext} from 'react'
import {useDropzone} from 'react-dropzone'
import "react-dropzone/examples/theme.css";
import { NotificationContextType } from "../../types";
import { NotificationContext } from "../../components/NotificationPane";

type FileDropzoneProps = {
  dir: string
  onUpload: () => void
}

function FileDropzone({dir, onUpload}: FileDropzoneProps) {

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { pushNotification } = useContext<NotificationContextType | null>(NotificationContext)!;

  async function uploadFile(fileName: string, fileContent: ArrayBuffer){
    if (instance && account) {
      try {
        const accessToken = await getAccessToken(instance, account);
        console.log(fileContent)
        const byteArray = Array.from(new Uint8Array(fileContent));
        const body = { fileName, byteArray, dir }
        const response = await fetch(`${import.meta.env.VITE_API_URL}/files/upload`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          throw new Error(`Response status: ${response.status}`);
        }
        const responseJson = await response.json();
        console.log(responseJson)
        onUpload();
        pushNotification(`File ${fileName} uploaded successfully`, "success");
      } catch (error) {
        pushNotification("Error uploading file", "error");
      }
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onloadend = () => {
        uploadFile(file.name, reader.result as ArrayBuffer);
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps({className: 'dropzone'})}  style={{ width: "100%", height: "200px" }}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files and upload to the currently selected directory</p>
    </div>
  )
}

export default FileDropzone